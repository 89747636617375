import "bootstrap"
import "../stylesheets/bootstrap.scss";

document.addEventListener("turbolinks:load", () => {
  //console.log("jQuery v" + jQuery.fn.jquery); // => "3.4.1"
  //console.log("Bootstrap v" + $.fn.tooltip.Constructor.VERSION); // => "4.3.1"

  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})
